import React from "react";
import Card from "../Components/UI/Card";
import VideoOverlay from "../Components/videos/APVideo.mp4";
import "./style/pages.css";

function Home() {
  return (
    <div className="home-background">
      <video
        muted
        loop
        autoPlay
        playsInline
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -43%)",
          zIndex: "-1"
        }}
      >
        <source src={VideoOverlay} type="video/mp4" />
      </video>

      <Card>
        <h2>Hello world, my name is Mike. I am an email and web producer. Email me at michaelrivasnyc@gmail.com if you need a website.</h2>
      </Card>
    </div>
  );
}

export default Home;
